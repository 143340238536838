import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionAboutVideo from "./SectionAboutVideo";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
const { REACT_APP_SITE_KEY } = process.env;
export interface PageAboutProps {
  className?: string;
  autoFocus?: boolean;
}
const PageAbout: FC<PageAboutProps> = ({
  className = "",
  autoFocus = false
}) => {

  const [details, setDetails] = useState({
    name: "",
    email: "",
    contactnumber: "",
    subject: "Keep in Touch with Tiny Away",
    message: "",
    google_captcha: ""
  });
  const [showPopover, setShowPopover]                       = useState(autoFocus);
  const [description, setDescription]                         = useState("");
  const [metaTitle, setMetaTitle]                             = useState("");
  const [bannnerUrl, setBannnerUrl]                           = useState("");
  const [isVideo, setIsVideo]                                 = useState("");
  const [isImages, setIsImages]                               = useState("no");
  const [isdataLoaded, setIsdataLoaded]                       = useState(false);
  const NameRef                                             = useRef<HTMLInputElement>(null);
  const EmailRef                                            = useRef<HTMLInputElement>(null);
  const ContactNumberRef                                    = useRef<HTMLInputElement>(null);
  const SubjectRef                                          = useRef<HTMLInputElement>(null);
  const MessagesRef                                         = useRef<HTMLInputElement>(null);
  const captchaRef                                          = useRef<ReCAPTCHA>(null);
  const [errorMsg, setErrorMsg]                             = useState('');
  const [successMsg, setSuccessMsg]                         = useState('');


  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+'getPages?page=about_us')
  .then(res => {
    console.log(res.data);
    setDescription(res.data.page_details.content_html);
    setMetaTitle(res.data.page_details.meta_title);
    setBannnerUrl(res.data.page_details.banner_url);
    setIsVideo(res.data.page_details.is_video);
    setIsImages(res.data.page_details.is_images);
    setIsdataLoaded(true);
  })
}, [isdataLoaded]);
const renderskeletonLoader = () => {
  return (
    <div className="custom-loader"><div className="h-screen skeleton"></div></div>
  );
};


const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  const isInvalid = validateInput();
  if (!isInvalid) {
    //const token = captchaRef.current?.getValue();
      captchaRef.current?.reset();
     // console.log(token);
      //console.log(details.google_captcha);
      
    axios.post(process.env.REACT_APP_BASE_URL + '/customer_enquiries', details)
      .then(function (response) {
        //console.log(response.data.response);
        if (response.data.status == 200) {
          setSuccessMsg(response.data.response);
          setDetails({
            name: "",
            email: "",
            contactnumber: "",
            subject: "",
            message: "",
            google_captcha: ""
          });
        } else {
          setErrorMsg(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    setSuccessMsg('');
  }
};
useEffect(() => {
  //console.log({ showPopover, 'EmailRef': EmailRef.current, 'NameRef': NameRef.current });
  if (showPopover && NameRef.current && !details.name) {
    NameRef.current.focus();
  } else if (showPopover && EmailRef.current && !details.email) {
    EmailRef.current.focus();
  } else if (showPopover && ContactNumberRef.current && !details.contactnumber) {
    ContactNumberRef.current.focus();
  } else if (showPopover && SubjectRef.current && !details.subject) {
    SubjectRef.current.focus();
  } else if (showPopover && MessagesRef.current && !details.message) {
    MessagesRef.current.focus();
  }
}, [showPopover]);
const validateInput = () => {
  const fields = [
    {
      name: 'name',
      value: details.name,
      message: 'Your Name should not be blank.'
    },
    {
      name: 'email',
      value: details.email,
      message: 'Your Email should not be blank.'
    },
    {
      name: 'contactnumber',
      value: details.contactnumber,
      message: 'Contact Number should not be blank.'
    },
    {
      name: 'subject',
      value: details.subject,
      message: 'Subject should not be blank.'
    },
    {
      name: 'message',
      value: details.message,
      message: 'Message should not be blank.'
    },
    {
      id: 'google_captcha',
      value: details.google_captcha,
      message: 'Captcha should not be blank.'
    }
  ]
  const isNotFilled = fields.some(field => {
    if (field.value.trim() === '') {
      setErrorMsg(field.message);
      setShowPopover(true);
      return true;
    }
    setShowPopover(false);
    setErrorMsg('');
    return false;
  });
  return isNotFilled;
};
 function SetRecaptchaVal(value: any) {
   setDetails({ ...details, google_captcha:value});
 }

  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet title={metaTitle}></Helmet>
      <BgGlassmorphism />
      {isdataLoaded  ?
      <div className=" w-full">
        
      <div className="our-tiny p-0 pl-0">
      {window.location.hostname=='tinyaway.com.tw' || window.location.hostname=='www.tinyaway.com.tw' ?
          <>
          <div className="tw-banner">
              <img src="https://cdn.tinyaway.com/common/2024/03/Banner_TAT_Web_OurStory1709564548706.jpg" alt=""/>
              <h1><span>Tiny Away</span> 開啟大自然中的現代旅遊住宿體驗</h1>
          </div>
            <div className="about-tw">
              <div className="tw-container">
                <div className="row-tw">
                  <div className="tw-6">
                    <div>
                    <h2 className="NotoSansTC">歡迎來到 <br/> <span className="WorkSans">Tiny Away</span></h2>
                    <p>Tiny Away是Big Tiny集團中的旗艦級品牌，致力於在遼闊的大自然中，提供一處現代、方便的休憩點，為尋求寧靜的旅者建立短暫的避風港。以容身大自然為核心價值，Tiny Away所建立的移動微型屋，是以環保建材所建立的綠色建築，在澳洲、紐西蘭、歐洲和台灣，目前Tiny Away共經營著400間以上的現代精緻移動微型屋，並連續五年榮獲了 Airbnb 超級房東的認證。</p>
                    <p> 我們與新加坡政府合作的 "Tiny Away Escape @ Lazarus Island"環保永續渡假島計畫也證明了我們的承諾：提供獨樹一幟輕奢住宿體驗的同時，也維持與大自然環境的和諧。</p>
                    </div>
                  </div>
                  <div className="tw-6">
                    <img src="https://cdn.tinyaway.com/common/2024/03/img2_TAT_Web_OurStory1709564587620.jpg" />
                  </div>
                </div>
                <div className="row-tw order-sm-tw">
                  <div className="tw-6 order-1-tw">
                    <img className="mt-100" src="https://cdn.tinyaway.com/common/2024/03/img_1TAT_Web_OurStory1709564563462.jpg" />
                  </div>
                  <div className="tw-6 order-2-tw">
                    <div className="">
                      <h3 className="">共創環保旅行新境界</h3>
                      <p>自創立以來，Tiny Away盡心致力於綠色生態旅遊和永續經營，我們最自豪的除了移動微型屋的建材是全部使用可回收的環保建材外，更將移動微型屋裝上車輪大幅降低破壞土地的可能性，以最大限度地減少碳足跡。</p>
                      <p>同時每座移動微型屋必會配置設備完善的廚房、衛浴設備及一張大床，完美結合自然與現代舒適的居住環境，貫徹我們"發現自然，享受舒適生活"的經營理念與使命。</p>
                      <p> Tiny Away選擇來到台灣，不僅僅為了發展更多元的旅遊體驗，更重要的是發現台灣市場在永續發展方面的用心與Tiny Away的核心價值不謀而合。保護自然環境的重要性深植於台灣人的心中，我們也期盼能在永續發展的領域中與台灣攜手努力共同做出貢獻！</p>
                    </div>
                  </div>
                </div>
                <div className="row-tw">
                <div className="tw-6 mt-100">
                    <div className="">
                      <h2 className="NotoSansTC">關於 <br/> <span className="WorkSans">Tiny Away</span> 的故事</h2>
                      <p>我們隸屬於總部位於新加坡的Big Tiny集團。為繁忙的現代生活開闢獨特的渡假勝地，讓長期在紛擾、吵雜環境中的都市生活的人們，提供一個舒適的環境放鬆身心靈，且能容身大自然中，留下一段美好回憶。我們小小願景的故事開始於2017年的新加坡，三位創辦人同時也是好友的Adrian Chia謝國嶸、Dave Ng黃克偉、Jeff Yeo楊智昇，在富有遠見和環保意識投資者支持下，在2021年11月為Big Tiny募集到300萬美元的A輪前資金。</p>
                      <p>自2017創立至今，Tiny Away在世界各地已有許多農場主，酒莊莊園老闆以及各處擁有美好自然景觀的地主加入我們，成為Tiny Away移動微型屋無限可能的選擇之一，也成為Tiny Away的經營者。同樣讓更多想一圓民宿老闆夢想的夥伴購買我們的移動微型屋，找到了夢想中的環境與旅宿樣貌，輕鬆成為包租公包租婆。而在加入我們後，Tiny Away將負起所有建造移動微型屋的責任，屋主不用擔心繁瑣的營運問題，我們有極其完整、專業的團隊負責處理宣傳、訂房、維修與客戶服務等，無須親自面對住客。而地主則會有團隊協助處理放置移動微型屋等所有問題。結合環保、親近自然及當地文化的獨有旅遊模式，開創了不一樣的可能。</p>
                      <p> 這就是Tiny Away的故事，Tiny Away非常期盼能邀請大家加入我們，一同享受這趟旅程，我們將重新定義環保旅行和永續生活。在Big Tiny集團，我們深信現代化的舒適與大自然之間是能夠和諧共存，並且會繼續努力地維護人類與大自然的平衡生活。感謝您陪我們一起看到目前為止的故事，也期待能與您一起續寫更多篇章，一起留下更多難得的體驗與珍貴的回憶。</p>
                    </div>
                </div>
                <div className="tw-6">
                  <img className="mt-150" src="https://cdn.tinyaway.com/common/2024/03/img_2TAT_Web_OurStory1709564574817.jpg" />
                </div>
                </div>
                <div className="row-tw">
                  <div className="btn-center"> 
                  <ButtonPrimary type="submit" className="btn-bry-tw">台灣訂房網</ButtonPrimary> 
                  <ButtonPrimary type="submit" className="btn-outline-tw">國際訂房網</ButtonPrimary>
                  </div>
                </div>
              </div>
              <div className="tw-page-video">
              <div className="container">
                <h4><span>Tiny Away</span> 除了旅遊還有更多可能</h4>
                
                <div className="video-tw">
                <iframe width="100%" height="600px" src="https://www.youtube.com/embed/r-XNt1SJtP4" title="在台灣，擁有一間無限可能的移動微型屋"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
              </div>

      <div className="text-tw-video">
              <p className="flex gap-4 items-center justify-center mb-1">想擁有屬於自己的移動微型屋，成為大房東 <img src="https://cdn.tinyaway.com/common/2024/03/icon-right-tw1709653122219.png"/></p>
              <p className="flex gap-4 items-center justify-center mb-5">擁有美麗風景，想成為Tiny Away的地主合作夥伴 <img src="https://cdn.tinyaway.com/common/2024/03/icon-right-tw1709653122219.png"/></p>
              </div>
              </div>
              </div>
              <div className="m-auto mb-5 mt-5 pb-14 pt-10 w-50">
              <div className="w-100 pl-20 pe-20">
                <h1 className="h1 capitalize text-center">Connect with our Team -<br></br> 連絡我們的團隊</h1>
              </div>
              {successMsg &&
                  <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                    {successMsg}
                  </div>}
                {errorMsg &&
                  <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                    {errorMsg}</div>}
                  <form className="grid grid-cols-1 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
                    <label className="block">
                      <Label>名字 *</Label>
                      <Input
                        type="text"
                        placeholder=""
                        name="name"
                        id="name"
                        onChange={event => setDetails({ ...details, name: event.target.value })}
                        ref={NameRef}
                        autoComplete="off"
                        autoFocus={showPopover}
                        value={details.name}
                      />
                    </label>
                    <label className="block">
                      <Label>電子郵件 *</Label>
                      <Input
                        type="email"
                        placeholder=""
                        name="email"
                        id="email"
                        onChange={event => setDetails({ ...details, email: event.target.value })}
                        ref={EmailRef}
                        autoComplete="off"
                        autoFocus={showPopover}
                        value={details.email}
                      />
                    </label>
                    <label className="block">
                      <Label>聯絡電話</Label>
                      <Input
                        type="text"
                        placeholder=""
                        name="contactnumber"
                        id="contactnumber"
                        onChange={event => setDetails({ ...details, contactnumber: event.target.value })}
                        ref={ContactNumberRef}
                        autoComplete="off"
                        autoFocus={showPopover}
                        value={details.contactnumber}
                      />
                    </label>
                    <label className="block">
                      <Label>主題 *</Label>
                      <Input
                        type="text"
                        placeholder=""
                        name="subject"
                        id="subject"
                        onChange={event => setDetails({ ...details, subject: event.target.value })}
                        ref={SubjectRef}
                        autoComplete="off"
                        autoFocus={showPopover}
                        value={details.subject}
                      />
                    </label>
                    <label className="block">
                      <Label>訊息 *</Label>
                      <Textarea placeholder=""
                        name="message"
                        id="message"
                        onChange={event => setDetails({ ...details, message: event.target.value })}
                        autoComplete="off"
                        autoFocus={showPopover}
                        value={details.message} rows={6}
                      />
                    </label>
                    <div>
                    <ReCAPTCHA
                        // size="invisible"
                        sitekey={REACT_APP_SITE_KEY!}
                        ref={captchaRef}
                        id="google_captcha"
                        onChange={SetRecaptchaVal}
                      />
                    </div>
                    <div>
                      <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                    </div>
                  </form>
              </div>
          </div>
          </>
          :
          <>
            {isVideo=='yes'?
                <video width="100%"  loop autoPlay={true} height="100%" controls className="group  bg-neutral-800 rounded-l overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px]" > 
                <source src={bannnerUrl} type="video/mp4"/> </video>
              :
                ""
              }
              {isImages=='yes'?
                <img className="w-full" src={bannnerUrl} alt="hero" />
              :
                ""
              }
              <div className="about-tw">
          <div className="common-content-descriptions"  dangerouslySetInnerHTML={ {__html: description} } />
          <div className="m-auto  w-50 mt-5 mb-5">
          {successMsg &&
                <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                  {successMsg}
                </div>}
              {errorMsg &&
                <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                  {errorMsg}</div>}
                <form className="grid grid-cols-1 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
                  <label className="block">
                    <Label>Your Name</Label>
                    <Input
                      type="text"
                      placeholder=""
                      name="name"
                      id="name"
                      onChange={event => setDetails({ ...details, name: event.target.value })}
                      ref={NameRef}
                      autoComplete="off"
                      autoFocus={showPopover}
                      value={details.name}
                    />
                  </label>
                  <label className="block">
                    <Label>Your Email</Label>
                    <Input
                      type="email"
                      placeholder=""
                      name="email"
                      id="email"
                      onChange={event => setDetails({ ...details, email: event.target.value })}
                      ref={EmailRef}
                      autoComplete="off"
                      autoFocus={showPopover}
                      value={details.email}
                    />
                  </label>
                  <label className="block">
                    <Label>Contact Number</Label>
                    <Input
                      type="text"
                      placeholder=""
                      name="contactnumber"
                      id="contactnumber"
                      onChange={event => setDetails({ ...details, contactnumber: event.target.value })}
                      ref={ContactNumberRef}
                      autoComplete="off"
                      autoFocus={showPopover}
                      value={details.contactnumber}
                    />
                  </label>
                  <label className="block">
                    <Label>Subject</Label>
                    <Input
                      type="text"
                      placeholder=""
                      name="subject"
                      id="subject"
                      onChange={event => setDetails({ ...details, subject: event.target.value })}
                      ref={SubjectRef}
                      autoComplete="off"
                      autoFocus={showPopover}
                      value={details.subject}
                    />
                  </label>
                  <label className="block">
                    <Label>Message</Label>
                    <Textarea placeholder=""
                      name="message"
                      id="message"
                      onChange={event => setDetails({ ...details, message: event.target.value })}
                      autoComplete="off"
                      autoFocus={showPopover}
                      value={details.message} rows={6}
                    />
                  </label>
                  <div>
                  <ReCAPTCHA
                      // size="invisible"
                      sitekey={REACT_APP_SITE_KEY!}
                      ref={captchaRef}
                      id="google_captcha"
                      onChange={SetRecaptchaVal}
                    />
                  </div>
                  <div>
                    <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                  </div>
                </form>
            </div>
          </div>
          </>
        }
      </div>
      </div>
      :
      <div className="container py-16 pt-0 lg:py-18 space-y-16 lg:space-y-28">
        {renderskeletonLoader()}
      </div>
      }
    </div>
    
  );
};

export default PageAbout;
