import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";


const { REACT_APP_SITE_KEY } = process.env;

export interface PageContactProps {
  className?: string;
  autoFocus?: boolean;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "<p>3/ 23-25 Lara Way, Campbellfield, VIC 3061, Australia</p> <p>294 Palmyra Avenue, Shanes Park, NSW 2747, Australia</p>",
  },
  {
    title: "💌 EMAIL",
    desc: "ask@tinyaway.com",
  },

];


const infointernal = [
  {
    title: "🗺 ADDRESS",
    desc: "<p>1 Pemimpin Drive, #04-06 One Pemimpin, Singapore 576151 </p><p>1 Tuas South Ave 6, #03-06 The Westcom, Singapore 637021</p><p>​No 43 Jalan SME 3, Kawansan Perindustian SME, Bandar Indahpura, 81000, Kulai, Johor, Malaysia</p>",
  },
  {
    title: "💌 EMAIL",
    desc: "ask@bigtiny.com.sg ",
  },
  {
    title: "☎ PHONE",
    desc: "+65 6525 5282",
  },
];


const PageContact: FC<PageContactProps> = ({
  className = "",
  autoFocus = false
}) => {

  const [details, setDetails] = useState({
    name: "",
    email: "",
    contactnumber: "",
    subject: "Keep in Touch with Tiny Away",
    message: "",
    google_captcha: ""
  });
  const [showPopover, setShowPopover]                       = useState(autoFocus);
  const containerRef                                        = useRef<HTMLDivElement>(null);
  const inputRef                                            = useRef<HTMLInputElement>(null);
  const NameRef                                             = useRef<HTMLInputElement>(null);
  const EmailRef                                            = useRef<HTMLInputElement>(null);
  const ContactNumberRef                                    = useRef<HTMLInputElement>(null);
  const SubjectRef                                          = useRef<HTMLInputElement>(null);
  const MessagesRef                                         = useRef<HTMLInputElement>(null);
  const captchaRef                                          = useRef<ReCAPTCHA>(null);
  const [errorMsg, setErrorMsg]                             = useState('');
  const [successMsg, setSuccessMsg]                         = useState('');

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInvalid = validateInput();
    if (!isInvalid) {
      //const token = captchaRef.current?.getValue();
        captchaRef.current?.reset();
       // console.log(token);
        //console.log(details.google_captcha);
        
      axios.post(process.env.REACT_APP_BASE_URL + '/customer_enquiries', details)
        .then(function (response) {
          //console.log(response.data.response);
          if (response.data.status == 200) {
            setSuccessMsg(response.data.response);
            setDetails({
              name: "",
              email: "",
              contactnumber: "",
              subject: "",
              message: "",
              google_captcha: ""
            });
          } else {
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessMsg('');
    }
  };
  useEffect(() => {
    //console.log({ showPopover, 'EmailRef': EmailRef.current, 'NameRef': NameRef.current });
    if (showPopover && NameRef.current && !details.name) {
      NameRef.current.focus();
    } else if (showPopover && EmailRef.current && !details.email) {
      EmailRef.current.focus();
    } else if (showPopover && ContactNumberRef.current && !details.contactnumber) {
      ContactNumberRef.current.focus();
    } else if (showPopover && SubjectRef.current && !details.subject) {
      SubjectRef.current.focus();
    } else if (showPopover && MessagesRef.current && !details.message) {
      MessagesRef.current.focus();
    }
  }, [showPopover]);
  const validateInput = () => {
    const fields = [
      {
        name: 'name',
        value: details.name,
        message: 'Your Name should not be blank.'
      },
      {
        name: 'email',
        value: details.email,
        message: 'Your Email should not be blank.'
      },
      {
        name: 'contactnumber',
        value: details.contactnumber,
        message: 'Contact Number should not be blank.'
      },
      {
        name: 'subject',
        value: details.subject,
        message: 'Subject should not be blank.'
      },
      {
        name: 'message',
        value: details.message,
        message: 'Message should not be blank.'
      },
      {
        id: 'google_captcha',
        value: details.google_captcha,
        message: 'Captcha should not be blank.'
      }
    ]
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        setShowPopover(true);
        return true;
      }
      setShowPopover(false);
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
   function SetRecaptchaVal(value: any) {
     setDetails({ ...details, google_captcha:value});
   }

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet title={'Contact Us - Tiny Away | Tiny House Weekend Getaways in Australia'}></Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Keep in Touch
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="d-flex-contact">
            <div className="form-contact">
              {successMsg &&
                <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
                  {successMsg}
                </div>}
              {errorMsg &&
                <div className="alert alert-warning" role="warning alert"><span className="las la-exclamation-circle"></span>
                  {errorMsg}</div>}
              <form className="grid grid-cols-1 gap-6" action="#" onSubmit={submitHandler} autoComplete="off" method="post">
                <label className="block">
                  <Label>Your Name</Label>
                  <Input
                    type="text"
                    placeholder=""
                    name="name"
                    id="name"
                    onChange={event => setDetails({ ...details, name: event.target.value })}
                    ref={NameRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.name}
                  />
                </label>
                <label className="block">
                  <Label>Your Email</Label>
                  <Input
                    type="email"
                    placeholder=""
                    name="email"
                    id="email"
                    onChange={event => setDetails({ ...details, email: event.target.value })}
                    ref={EmailRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.email}
                  />
                </label>
                <label className="block">
                  <Label>Contact Number</Label>
                  <Input
                    type="text"
                    placeholder=""
                    name="contactnumber"
                    id="contactnumber"
                    onChange={event => setDetails({ ...details, contactnumber: event.target.value })}
                    ref={ContactNumberRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.contactnumber}
                  />
                </label>
                <label className="block">
                  <Label>Subject</Label>
                  <Input
                    type="text"
                    placeholder=""
                    name="subject"
                    id="subject"
                    onChange={event => setDetails({ ...details, subject: event.target.value })}
                    ref={SubjectRef}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.subject}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea placeholder=""
                    name="message"
                    id="message"
                    onChange={event => setDetails({ ...details, message: event.target.value })}
                    autoComplete="off"
                    autoFocus={showPopover}
                    value={details.message} rows={6}
                  />
                </label>
                <div>
                <ReCAPTCHA
                    // size="invisible"
                    sitekey={REACT_APP_SITE_KEY!}
                    ref={captchaRef}
                    id="google_captcha"
                    onChange={SetRecaptchaVal}
                  />
                </div>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>

            <div className="max-w-sm space-y-8">
              <div className="address-tiny">
                <h2>Australia Offices</h2>
                {info.map((item, index) => (
                  <div key={index}>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      {item.title}
                    </h3>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      <div dangerouslySetInnerHTML={{ __html: item.desc }} />

                    </span>
                  </div>
                ))}
              </div>
              <div className="address-tiny">
                <h2>International Offices</h2>
                {infointernal.map((item, index) => (
                  <div key={index}>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      {item.title}
                    </h3>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                    </span>
                  </div>
                ))}
              </div>
              <div>


                {/*<h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
              </h3>
                <SocialsList className="mt-2" />*/}
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
